'use client';

import React, { LegacyRef } from 'react';
import { toast } from 'react-toastify';

import LoadingFullScreen from '../shared/LoadingFullScreen';

import { FaProjectDiagram } from 'react-icons/fa';
import { usePathname, useRouter } from 'next/navigation';
import { slugify } from '../lib/slugify';
import { on } from 'events';
import type { CareerMap, CareerMapPreDB } from '../types';
import Link from 'next/link';
import { Typewriter, useTypewriter } from 'react-simple-typewriter';
import { useAtom } from 'jotai';
import { applicationStateAtom } from '../jotaiStore';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
// const testError = async () => {
//   throw new Error('Test Error');
// };

export const SuggestedJobCard = ({ text, handleClick }: any) => {
  // React.useEffect(() => {
  //   testError();
  // });

  return (
    <>
      <button
        className={`group backdrop-blur-md bg-transmarent flex-1 min-[370px]:px-4 px-2 min-[370px]:py-4 py-2  border-2 border-white/20 border-dashed hover:shadow-xl  rounded-lg text-white/60 transition-all duration-300 cursor-pointer hover:text-white/90 hover:bg-colors-green/80 hover:ring-emerald-600/50 hover:border-transparent hover:border-solid hover:backdrop-blur-lg group text-sm sm:text-[17.5px] sm:leading-[27px]`}
        onClick={() => {
          // error handled in handleClick
          handleClick(text);
        }}
        value={text}
        name="objectiveText"
        type="button"
      >
        <div className="flex flex-col items-center justify-center h-full transition-all duration-100 delay-0">
          <p className="text-center group-hover:text-shades-baseDark transition-all duration-100">
            {text}
          </p>
        </div>
      </button>
    </>
  );
};

const CreateMapForm: React.FC<{}> = () => {
  const [objectiveText, setObjectiveText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [, resetState] = useAtom(applicationStateAtom);

  const searchBarRef = React.useRef(null);
  const router = useRouter();
  // temporary until useActionStatus is released
  const onSubmit = async (objective: any) => {
    resetState();
    let createdMap: { error?: string };

    try {
      setIsLoading(true);

      if (objective === '') {
        toast.warning('Please enter a job title', {
          toastId: 'EMPTY JOB TITLE',
          theme: 'dark',
        });
        setIsLoading(false);
        return;
      }
      // todo navigate to map page instead
      router.push(`/map/${slugify(objective)}`);
      // createdMap = await getNewMap(objective);
    } catch (error) {
      setIsLoading(false);
      toast.error('Error Creating Map: ' + objective, {
        toastId: 'ERROR_CREATING_MAP',
        theme: 'dark',
      });
      return;
    }
    // if (createdMap && 'error' in createdMap) {
    //   setIsLoading(false);
    //   console.error('Error Creating Map');
    //   return toast.error('Error Creating Map: ' + objective, {
    //     toastId: 'ERROR_CREATING_MAP',
    //     theme: 'dark',
    //   });
    // }
  };

  const [text, helper] = useTypewriter({
    words: [
      'Ai Engineer',
      'Day Trader',
      'Cognitive Psychologist',
      'UX Designer',
      'Data Scientist',
      'Startup Founder',
    ],
    loop: 0, // 0 means infinite
    // cursor: true,
    // cursorStyle: '_',
    typeSpeed: 80,
    deleteSpeed: 60,
    delaySpeed: 1000,
    // onLoopDone: handleDone,
    // onType: handleType,
  });

  return (
    <>
      {isLoading && <LoadingFullScreen />}
      <form
        role="search"
        className="w-full z-10"
        // action={onSubmit}
      >
        <label
          htmlFor="objectiveText"
          className="block text-[15.5px] font-medium leading-[26px] text-white/65 relative top-[7px] left-[1px]"
        >
          Job Title:
        </label>
        <div className="mt-[9px] relative flex rounded-md shadow-sm z-0">
          <div className="relative flex flex-grow items-stretch z-0">
            <input
              type="text"
              name="objectiveText"
              id="objectiveText"
              value={objectiveText}
              onChange={(e) => setObjectiveText(e.target.value)}
              ref={searchBarRef}
              maxLength={80}
              className="block relative w-full rounded-none rounded-l-md z-0 border-0 py-1.5 text-white/90 ring-1 ring-inset ring-white/20 placeholder:text-white/25 focus:ring-1 focus:ring-inset focus:ring-white/50 text-[17.5px] sm:leading-[32px] bg-white/10 backdrop-blur-md pl-[18px] shadow-md"
              placeholder={text}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSubmit(objectiveText);
                }
              }}
            />
          </div>
          <button
            aria-label="submit"
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-[12px] py-[9px] font-bold  bg-colors-orange  text-shades-baseDark sm:text-[18px] leading-[26px]  group border-amber-700 ring-shades-baseDark/50 ring-1 transition-all duration-300
             shadow-colors-orange shadow-[0px_0px_20px_-5px] hover:shadow-colors-orange hover:shadow-[0px_0px_30px_-5px]"
            onClick={() => {
              onSubmit(objectiveText);
            }}
          >
            <span className="hidden min-[400px]:block text-shades-base font-semibold px-[4px]">
              Create Map
            </span>
            <ArrowRightIcon className="min-[400px]:hidden w-5 h-5 mx-2 stroke-[3]" />
            {/* <SparklesIcon className="hidden min-[400px]:block w-5 h- stroke-[2]" /> */}
          </button>
        </div>
        <section
          className="flex gap-[20px] mt-[22px]"
          aria-label="job title autocomplete suggestions"
        >
          <SuggestedJobCard text="Digital Marketer" handleClick={onSubmit} />
          <SuggestedJobCard text="Front-end Developer" handleClick={onSubmit} />
          <SuggestedJobCard text="Poker Player" handleClick={onSubmit} />
        </section>
      </form>
    </>
  );
};

export default CreateMapForm;
