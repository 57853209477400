import { atom } from 'jotai';
import { chapterAtom, nextChapterAtom, prevChapterAtom } from './chaptersStore';
const INITIAL_INTRO_VALUE: any = {};

const INITIAL_CONTENT_VALUE: string = '';

// NEW STUFF

export const isStreamingMapAtom = atom(false);
export const streamMapErrorAtom = atom<Error | null>(null);
export const mapDataAtom = atom(INITIAL_INTRO_VALUE);

export const isStreamingContentAtom = atom(false);
export const streamContentErrorAtom = atom<Error | null>(null);

export const contentAtom = atom(
  (get) => {
    const currentMap = get(mapDataAtom);
    
    const chapter = get(chapterAtom);
    
    if (!currentMap || chapter === null || chapter === undefined) {
      return '';
    }
    
    if (chapter === 'overview') {
      return currentMap.jobDescription || '';
    }

    const { subTopicIndex, topicIndex } = chapter;

    if (
      subTopicIndex !== -1 &&
      subTopicIndex !== null &&
      topicIndex !== -1 &&
      topicIndex !== null
    ) {
      return currentMap.topicList[topicIndex].subTopics[subTopicIndex].content;
    } else {
      return currentMap.jobDescription || '';
    }
  },
  (_get, set, newText) => {
    const currentMap = _get(mapDataAtom);

    const chapter = _get(chapterAtom);
    if (!currentMap || chapter === null || chapter === undefined || chapter === 'overview') {
      return;
    }

    // update the content of the subtopic
    const { subTopicIndex, topicIndex } = chapter;

    if (subTopicIndex !== null && topicIndex !== null) {
      const updatedSubtopics = [...currentMap.topicList[topicIndex].subTopics];
      updatedSubtopics[subTopicIndex].content = newText;
      const updatedTopicList = [...currentMap.topicList];
      updatedTopicList[topicIndex].subTopics = updatedSubtopics;
      set(mapDataAtom, { ...currentMap, topicList: updatedTopicList });
    } else {
      set(mapDataAtom, { ...currentMap, jobDescription: newText });
    }
  }
);

export const mobileSidebar = atom(false);
export const desktopSidebar = atom(true);
export const mapViewAtom = atom(false);

export const chaptersOpenStatesAtom = atom<boolean[]>([true]);

// DERIVED ATOMS
export const isEitherStreamingAtom = atom((get) => {
  return get(isStreamingContentAtom) || get(isStreamingMapAtom);
});

// Add this near the end of the file, with the other derived atoms
export const applicationStateAtom = atom((get) => {
  return {
    // Map related
    isStreamingMap: get(isStreamingMapAtom),
    streamMapError: get(streamMapErrorAtom),
    mapData: get(mapDataAtom),

    // Chapter related
    currentChapter: get(chapterAtom),
    nextChapter: get(nextChapterAtom),
    prevChapter: get(prevChapterAtom),
    chaptersOpenStates: get(chaptersOpenStatesAtom),

    // Content related
    isStreamingContent: get(isStreamingContentAtom),
    streamContentError: get(streamContentErrorAtom),
    content: get(contentAtom),

    // UI State
    mobileSidebar: get(mobileSidebar),
    desktopSidebar: get(desktopSidebar),
    mapView: get(mapViewAtom),

    // Derived states
    isEitherStreaming: get(isEitherStreamingAtom),
  };
}, (get, set) => {
  set(mapDataAtom, null);
  set(contentAtom, null);
  set(isStreamingContentAtom, false);
  set(streamContentErrorAtom, null);
  set(isStreamingMapAtom, false);
  set(streamMapErrorAtom, null);
});
