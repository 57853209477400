import { atom } from 'jotai';
import { isStreamingContentAtom } from '.';
import { mapDataAtom } from '.';
import { isStreamingMapAtom } from '.';
import { slugify } from '../lib/slugify';

export const chapterAtom = atom<
  undefined
  | null
  | 'overview'
  | {
      topicIndex: number;
      subTopicIndex: number;
    }
>(undefined);

export const nextChapterAtom = atom((get) => {
  const chapter = get(chapterAtom);
  const isStreamingMap = get(isStreamingMapAtom);
  const isStreamingContent = get(isStreamingContentAtom);
  const mapData = get(mapDataAtom);

  if (isStreamingMap) {
    return null;
  }

  if (chapter === null || chapter === undefined || mapData === null) {
    return null;
  }

  if (chapter === 'overview') {
    return {
      nextTopicIndex: 0,
      nextSubTopicIndex: 0,
      nextSubtopicTitle: mapData.topicList[0].subTopics[0].subTopicName,
      nextSubtopicSlug: slugify(mapData.topicList[0].subTopics[0].subTopicName),
    };
  }

  const { topicIndex, subTopicIndex } = chapter;

  if (topicIndex === null || subTopicIndex === null) {
    if (mapData.topicList.length > 0) {
      return {
        nextTopicIndex: 0,
        nextSubTopicIndex: 0,
        nextSubtopicTitle: mapData.topicList[0].subTopics[0].subTopicName,
        nextSubtopicSlug: mapData.topicList[0].subTopics[0].slug,
      };
    }
    return null;
  }

  const currentTopic = mapData.topicList[topicIndex];
  if (!currentTopic) {
    return null;
  }

  if (subTopicIndex < currentTopic.subTopics.length - 1) {
    // If there are more subtopics in the current topic
    return {
      nextTopicIndex: topicIndex,
      nextSubTopicIndex: subTopicIndex + 1,
      nextSubtopicTitle: currentTopic.subTopics[subTopicIndex + 1].subTopicName,
      nextSubtopicSlug: currentTopic.subTopics[subTopicIndex + 1].slug,
    };
  } else if (topicIndex < mapData.topicList.length - 1) {
    // If we're at the last subtopic of the current topic, move to the next topic
    return {
      nextTopicIndex: topicIndex + 1,
      nextSubTopicIndex: 0,
      nextSubtopicTitle:
        mapData.topicList[topicIndex + 1].subTopics[0].subTopicName,
      nextSubtopicSlug: mapData.topicList[topicIndex + 1].subTopics[0].slug,
    };
  } else {
    // If we're at the last subtopic of the last topic, return null
    return null;
  }
});

export const prevChapterAtom = atom((get) => {
  const chapter = get(chapterAtom);
  const isStreamingMap = get(isStreamingMapAtom);
  const isStreamingContent = get(isStreamingContentAtom);
  const mapData = get(mapDataAtom);

  if (isStreamingMap || !mapData) {
    return null;
  }

  if (chapter === null || chapter === undefined) {
    return null;
  }

  if (chapter === 'overview') {
    return null;
  }

  const { topicIndex, subTopicIndex } = chapter;

  if (subTopicIndex > 0) {
    // If there are previous subtopics in the current topic
    return {
      prevTopicIndex: topicIndex,
      prevSubTopicIndex: subTopicIndex - 1,
      prevSubtopicTitle:
        mapData.topicList[topicIndex].subTopics[subTopicIndex - 1].subTopicName,
      prevSubtopicSlug:
        mapData.topicList[topicIndex].subTopics[subTopicIndex - 1].slug,
    };
  } else if (topicIndex > 0) {
    // If we're at the first subtopic of the current topic, move to the previous topic
    const prevTopic = mapData.topicList[topicIndex - 1];
    const prevSubTopicIndex = prevTopic.subTopics.length - 1;
    return {
      prevTopicIndex: topicIndex - 1,
      prevSubTopicIndex: prevSubTopicIndex,
      prevSubtopicTitle: prevTopic.subTopics[prevSubTopicIndex].subTopicName,
      prevSubtopicSlug: prevTopic.subTopics[prevSubTopicIndex].slug,
    };
  } else {
    // If we're at the first subtopic of the first topic, return null
    return null;
  }
});
